.noticia {
  padding: 15px 15px 0 15px;
  width: 55%;
}
.noticiapostagem11 {
  word-break: break-word;
}
.dia-data {
  display: inline-block;
  background-color: #4cf57c;
  border-radius: 8px;
  padding: 3px;
}
.linktag {
  margin-right: 10px;
}
.div-react-player {
  margin-top: 7px;
  display: flex;
  justify-content: center !important;
}
.noticia-texto {
  margin-top: 8px;
}
.youtubediv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.youtubevideo {
  margin: 20px 0;
  min-height: 390px;
  min-width: 70%;
}
/* media queries body */

@media (max-width: 991px) {
  .noticia-titulo {
    font-size: 26px;
  }
}
