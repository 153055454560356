.coluna-tags {
  padding: 8px;
}

.coluna-tags h5 {
  color: #4cf57c;
  font-weight: bold;
  text-align: center;
}

.item-tag {
  color: #42a5f5;
}
