.navbar-main {
  background-color: #f54c4c;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navbar-links {
  color: white;
  margin: 0 20px 0 20px;
  font-size: 18px;
}

.navbar-row:hover,
.navbar-slogan-mobile:hover,
.navbar-slogan:hover,
.navbar-links:hover {
  text-decoration: none;
  color: #ffffffd0;
}

.navbar-slogan,
.navbar-slogan-mobile {
  color: white;
  font-size: 18px;
}

.navbar-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
}

#bem-vindo-plataforma {
  cursor: default;
}

@media (max-width: 751px) {
  #link-borda {
    border-top: solid 1px white;
    padding-top: 10px;
    margin-top: 5px;
  }

  .navbar-slogan {
    display: none;
  }

  .icone-user {
    display: none;
  }
}

@media (min-width: 143px) {
  .navbar-slogan-mobile {
    display: none;
  }
}
