form {
  width: 70%;
}

.entrada {
  width: 100%;
  margin: 5px 0;
  
}

.termo_politica{
  color: gray;
  font-size:12px;
  text-align:justify;
}

.cadastro-container {
  background-color: #f66464;
  width: 100%;
  height: 90vh;
  display: flex;
  flex: 8;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.texto-cadastro {
  background-color: #f5db7d;
  color: #f54c4c;
  width: 100%;
  border-radius: 25% 0 0 0 / 50% 0;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.opcoes-cadastro {
  background-color: #f5db7d;
  width: 100%;
  flex: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 25% 0 / 12.5%;
}

.botoes-cadastro {
  color: aliceblue;
  margin: 15px;
  background-color: #f66464;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30%;
  max-height: 100px;
  width: 90%;
  max-width: 800px;
  border-radius: 16px;
  transition: filter 0.2s;
}

.botoes-cadastro:hover {
  filter: opacity(90%);
}

.form-select-label {
  color: #343a40;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* remove setinhas dos inputs tipo número */
  -webkit-appearance: none;
  margin: 0;
}

h2 {
  font-size: 28px !important;
  text-align: center;
}

h3 {
  font-size: 24px !important;
  text-align: center;
}

h5 {
  font-size: 18px !important;
  text-align: center;
}

@media (max-width: 1024px) {
  h2 {
    font-size: 28px !important;
  }
  h3 {
    font-size: 24px !important;
  }
  h5 {
    font-size: 14px !important;
  }
  form {
    width: 80%;
  }
  .texto-cadastro {
    border-radius: 0 0 0 0;
  }
  .opcoes-cadastro {
    border-radius: 0;
  }
  .botoes-cadastro {
    color: aliceblue;
    margin: 25px;
    background-color: #f66464;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30%;
    max-height: 100px;
    width: 90%;
    max-width: 800px;
    border-radius: 16px;
    transition: filter 0.2s;
  }
}

@media (max-width: 450px) {
  h2 {
    font-size: 28px !important;
  }
  h3 {
    font-size: 14px !important;
  }
  h5 {
    font-size: 10px !important;
  }
}
