.carrossel {
  display: block;
  background-color: #f66464;
  margin-top: 86px;
  justify-content: center;
}

.carousel .carousel-control-prev-icon,
.carousel .carousel-control-next-icon {
  width: 60px;
  height: 60px;
}
.photos {
  width: 100vh;
  display: block;
  margin: auto;
  object-fit: cover;  
}
#pesquisadores{
  object-position: top;
}
.texto {
  max-width: 888.88px;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-right: 3%;
  padding-left: 3%;
  margin: auto;
  text-align: center;
  font-size: calc(5px + 1vw);
  color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.658);
}
.card {
  color: 'white';
  float: inline-start;
  justify-content: center;
  align-items: center;
  padding: 10%;
}
.apresentacao {
  background-color: #f5db7d;
  text-align: center;
  margin-bottom: 30px;
}
.nav_tabs{
  max-width: 600px;
  height: auto;
  background-color: #F54C4C;
  margin: -20% auto auto;
  position: relative;
  align-self: center;
  box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.15);
}

.caixa{
  margin: 10% auto auto;
  padding-bottom: 800px;
  align-self: center;
}


.nav_tabs ul{
  list-style: none;
}

.nav_tabs ul li{
  float: left;
}

.tab_label{
  display: block;
  max-width: 150px;
  width: 100%;
  background-color: #F66464;
  padding: 10px;
  font-size: calc(10px + 1vw);
  color:#fff;
  cursor: pointer;
  text-align: center;
}


.nav_tabs .rd_tab { 
display:none;
position: absolute;
}

.nav_tabs .rd_tab:checked ~ label { 
background-color: #F54C4C;
color:#fff;}

.tab-content{
border-top: solid 5px #F54C4C;
background-color: #fff;
display: none;
position: absolute;
min-height: 400px;
max-width: 1000px;
width: auto;
height: auto;
left: 0;	
box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.15);
}

#justify4{
  text-align: justify;
  list-style-type: circle;
}

.rd_tab:checked ~ .tab-content{
display: block;
}
.tab-content h2{
padding: 10px;
color: #4CF57C;
}
.tab-content article{
padding: 10px;
color: #000;
list-style-type: circle;
}

.branco {
  background-color: 'white';
}

.follow-up {
  max-width: 100%;
  padding: 20%;
  margin: -6.875rem auto 0;
  margin-top: -30%;
  margin-right: auto;
  margin-bottom: 15px;
  margin-left: auto;
  box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.15);
}
#verde{
  background-color: #8af5a8;
}
#azul{
  background-color: #92c9f7;
}
#outroazul{
  background-color: #98a0f0;
  margin-bottom: -10%;
}
.margem {
  padding: 10%;
}

.cards-container {
  margin: 10px 0;
}

.noticia-container {
  margin: -10px 0;
}

.equipe-container {
  margin: -10px 0;
}
