.picker {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.container h1 {
  color: #f66464;
}
#textoprox2{
  font-size: 27px;
  color: #f66464;
  text-align: center;
}
.btn-send {
  margin-bottom: 1.2rem;
}
@media (max-width: 450px){
  #textoprox2{
    align-items: left;
    text-align: left;
    font-size: 25px ; 
    margin-left: -13px;
  }
}