.noticia-prev {
  background-color: #eee;
  padding: 15px 15px 0 15px;
  margin-bottom: 30px;
  border-radius: 8px;
  width: 55%;
  text-align: justify;
}

.noticia-titulo:hover {
  cursor: pointer;
}

.dia-data {
  display: inline-block;
  background-color: #4cf57c;
  border-radius: 8px;
  padding: 3px;
}

.mais-info {
  color: #4cf57c;
  font-weight: bold;
  text-align: justify;
}

.mais-info:hover {
  color: #4cf57cd0;
}
.noticia-resumo {
  margin-top: 8px;
  text-align: justify;
}

.noticia-imagem {
  text-align: center;
}

/* media queries do body */

@media (max-width: 768px) {
  .noticia-titulo {
    font-size: 26px;
  }
  p {
    font-size: 15px !important;
  }
  .linktag {
    font-size: 12px !important;
  }
}
