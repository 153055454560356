.body-wrapper {
  display: flex;
  flex-direction: row;
  flex: 12;
  background-color: #fffbee;
}

.content-wrapper {
  flex: 9;
  min-height: 100vh;
  background-color: #fffbee;
}

.pagina {
  color: #111111;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.estilo-titulo {
  height: 10px;
  background-color: #f5db7d2f;
  width: 50%;
  margin-top: -20px;
}

.titulo-pagina {
  font-size: 28px;
  margin: 5px 0;
}

.conteudo-pagina {
  width: 100%;
  max-width: 1120px;
  height: 100%;
  margin: 5px auto;
}

.quadrados {
  max-width: 100px;
  height: 100px;
  background-color: brown;
  margin: 5px;
}

@media (max-width: 751px) {
  .pagina {
    margin-left: 7px;
    margin-right: 15px;
    background-color: #fffbee;
  }
  
}
