.titulo-linha-da-lista {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.titulo-itens-da-linha {
  width: 100%;
  border-style: solid;
  border-color: #f5db7d;
  background-color: #f5db7d;
  border-width: 1px;
  display: flex;
  flex: 12;
  flex-direction: row;
  justify-content: space-around;
}

.titulo-itens1 .titulo-itens2 .titulo-itens3 {
  flex: 4;
  align-items: center;
  justify-content: center;
}

.titulo-botoes-lista {
  background-color: #5866f5;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.forum-messages {
  min-width: 100%;
  height: fit-content;
  flex-direction: column-reverse;
  margin: 20px;
  
  
}
@media (max-width: 492px){
#inputfont{
  font-size: 10px;
}
}