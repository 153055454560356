/* formulario de cadastro */

.container {
  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#espe1{
  margin: 5px;
}
#espe2{
  font-size: 15px;
}
.container h1 {
  padding-bottom: 1.2rem;
}
.cards{
  max-height: 350px;
  max-width: 350px;
  margin: 20px;
  
}
.container section {
  padding-bottom: 1.8rem;
}

.container .btn-send {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  display: inline-block;
  padding-top: 14px;
}
#bexigaa{
  height: 50%;
  width: 50%;
}
.textarea {
  width: 100%;
}

.linha {
  margin-top: 30px;
  background-color: white;
  color: white;
}

/* media queries */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {

    .cards{
      max-height: 550px;
      max-width : 550px;
      margin: 25px;
    }
    
}
@media only screen and (min-width: 1024px) and (max-width: 1366px){
  .cards{
    max-height: 570px;
    max-width : 550px;
    margin: 40px;
  }
}
@media only screen and (min-width: 834px) and (max-width: 1024px){
  .cards{
    max-height: 600px;
    max-width : 550px;
    margin: 20px;
  }
}
@media (max-width: 496px) {
  #segundo-instrumento {
    margin-top: 30px;
  }
}

@media (max-width: 768px){
  .img.col-lg{
    width: 80% !important;
  }
}
