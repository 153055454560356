.card-instrumentos-preencher {
  padding: 15px;
  border-radius: 32px;
  border-color: #5866f5;
  border-width: 6px;
  border-style: solid;
  height: 90%;
  cursor: pointer;
}

.card-instrumentos-preencher h1 {
  color: #4cf57c;
}

.img,
.titulo,
.linha-img-titulo {
  margin: auto;
}

@media (min-width:539px) and (max-width:541px){
  .card-instrumentos-preencher{
    width: 30vw;
  }

  .titulo{
    font-size: 12px !important;  
  }
}
