.linha-da-lista {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.itens-da-linha {
  width: 100%;
  border-style: solid;
  border-color: #f5db7d;
  background-color: #f5db7d;
  border-width: 0 1px 1px 1px;
}
.edtpost {
  word-break: break-word;
}
.delpost {
  word-break: break-word;
}
td {
  max-width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itens1PST {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 13ch;
  justify-content: center;
  position: absolute;
  margin-left: -610px;
}
.itens2PST {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: hidden;
  max-width: 117px;
  justify-content: center;
  display: block;
  float: none;
  position: absolute;
  margin-left: 20px;
}
.itens3PST {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 10ch;
  justify-content: center;
  display: block;
  float: none;
  position: absolute;
  margin-right: -640px;
}
@media (max-width: 492px) {
  .itens1PST {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 10ch;
    justify-content: center;
    margin: 15px;
    position: relative;
    justify-content: center;
  }
  .itens2PST {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: hidden;
    max-width: 117px;
    justify-content: center;
    margin: 15px;
    position: relative;
    justify-content: center;
  }
  .itens3PST {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 10ch;
    justify-content: center;
    margin: 15px;
    position: relative;
    justify-content: center;
  }
}
@media only screen and (min-width: 834px) and (max-width: 1024px) {
  .itens1PST {
    margin-left: -340px;
    justify-content: center;
  }
  .itens2PST {
    justify-content: center;
  }
  .itens3PST {
    justify-content: center;
    margin-left: -285px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .itens1PST {
    margin-left: -493px;
  }
  .itens2PST {
    margin-left: 18px;
  }
  .itens3PST {
    margin-left: -122px;
    overflow: hidden;
    white-space: nowrap;

    max-width: 9ch;
  }
}
.botoes-lista {
  background-color: #5866f5;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#imagemObrigatorio {
  color: rgb(92, 91, 91);
  font-size: x-small;
}
