.navbar-main {
  background-color: #f66464;
}

.navbar-links {
  color: white;
  margin: 0 20px 0 20px;
  font-size: 18px;
  cursor: pointer;
}

.login-cadastro{
  font-weight: 400;
  text-shadow: 1px 1px rgb(0, 0, 0);
}

.portal-informacoes{
  font-weight: 700;
  text-shadow: 1px 1px rgb(0, 0, 0);
}

.navbar-row:hover,
.navbar-slogan-mobile:hover,
.navbar-slogan:hover,
.navbar-links:hover {
  text-decoration: none;
  color: #ffffffd0;
}

.navbar-slogan,
.navbar-slogan-mobile {
  color: white;
  font-size: 18px;
}

.navbar-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
}

@media (max-width: 991px) {
  #link-borda {
    border-top: solid 1px white;
    padding-top: 10px;
    margin-top: 5px;
  }
}

@media (max-width: 492px) {
  .navbar-slogan {
    display: none;
  }

  .navbar-slogan-mobile {
    display: block;
    font-size: 14px;
  }
}

@media (min-width: 493px) {
  .navbar-slogan-mobile {
    display: none;
  }
}

@media (min-width: 2047px) and (max-width: 2048px){
  .navbar-main{
    height: 172px;
  }

  .d-inline-block{
    height: 120px;
    width: 120px;
  }

  .navbar-slogan , .navbar-links{
    font-size: 36px;
  }
}