.card-link-para-forum {
  width: 100%;
  min-height: 100px;
  border-style: solid;
  border-color: #f5db7d;
  border-radius: 5px;
  background-color: #f5db7d;
  border-width: 1px;
  margin-bottom: 10px;
  display: flex;
  flex: 12;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: black;
}

.card-link-para-forum:hover {
  text-decoration: none;
  opacity: 0.8;
}

.container-pastas-cada-consulta {
  width: 100%;
  min-height: 100px;
  border-style: solid;
  border-color: #f5db7d;
  border-radius: 5px;
  background-color: #f5db7d;
  border-width: 1px;
  margin-bottom: 10px;
  display: flex;
  flex: 12;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.titulo-container-pastas-cada-consulta {
  margin: 10px 0;
}

.cada-pasta-consulta {
  width: 90%;
  min-height: 45px;
  border-style: solid;
  border-color: #5866f5;
  border-radius: 5px;
  background-color: #5866f5;
  color: aliceblue;
  border-width: 1px;
  margin-bottom: 3px;
  display: flex;
  flex: 12;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
