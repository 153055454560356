.linha-da-lista {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.itens-da-linha {
  width: 100%;
  border-style: solid;
  border-color: #f5db7d;
  background-color: #f5db7d;
  border-width: 0 1px 1px 1px;
}
.itens1BP{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 13ch;
  justify-content: center;
  position: absolute;
  margin-left: -610px;
}
.itens2BP{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: hidden;
  max-width: 117px;
  margin-left: -90px;
  justify-content: center;
  display :block;
  float :none;
  position: absolute;
}
.itens3BP {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 14ch;
  justify-content: center;
  display :block;
  float :none;
  position: absolute;
  margin-left: 520px;
}
@media (max-width: 492px){
  .itens1BP{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 10ch;
    justify-content: center;
    margin: 20px;
    position: relative;
    width: 400px;
  }
  .itens2BP{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: hidden;
    max-width: 117px;
    justify-content: center;
    margin: 20px;
    position: relative;
    width: fit-content;
    width: 400px;
  }
  .itens3BP {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 14ch;
    justify-content: center;
    margin: 20px;
    position: relative;
    width: fit-content;
    width: 400px;
  }
}
@media only screen and (min-width: 834px) and (max-width: 1024px){
  .itens1BP{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 10ch;
    justify-content: center;
    margin: 20px;
    position: relative;
    width: 400px;
  }
  .itens2BP{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: hidden;
    max-width: 117px;
    justify-content: center;
    margin: 20px;
    position: relative;
    width: fit-content;
    width: 400px;
  }
  .itens3BP {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 14ch;
    justify-content: center;
    margin: 20px;
    position: relative;
    width: fit-content;
    width: 400px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px){
  
  .itens2BP{
   margin-left: 35px;
    
    
  }
  
}
@media only screen and (width: 768px) and (height: 1024px) and (orientation:portrait){
  .itens1BP{
    margin-left: -310px;
    
    
  }
  .itens2BP{
   margin-left: 10px;
    
    
  }
  .itens3BP{
    margin-left: 320px;
    
     
     
   }
}
.botoes-lista {
  background-color: #5866f5;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

