.linha-da-lista {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.itens-da-linha {
  width: 100%;
  border-style: solid;
  border-color: #f5db7d;
  background-color: #f5db7d;
  border-width: 0 1px 1px 1px;
  display: flex;
  flex: 12;
  flex-direction: row;
  justify-content: space-around;
}

.itens1 .itens2 {
  width: max-content;
  flex: 6;
  align-items: center;
  justify-content: center;
}

.botoes-lista {
  background-color: #5866f5;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
