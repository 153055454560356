/* formulario de cadastro */
.container {
  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container h1 {
  padding-bottom: 1.2rem;
}

.container section {
  padding-bottom: 1.8rem;
}

.container .btn-send {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  display: inline-block;
  padding-top: 14px;
  font-size: 10px;
}

.textarea {
  width: 100%;
}

#imagemObrigatorio{
  color:rgb(92, 91, 91);
  font-size:x-small;
}
#noticiacadastro{
  width: 100%;
}
@media (max-width: 768px){
  
}