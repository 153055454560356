.background-header {
  background: linear-gradient(50deg, #3173ce, #87cbfa);
  padding: 36px 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  border-radius: 0 0 8px 8px;
  margin-top: 86px;
}

.middle-text {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  max-width: 1300px;
}

.titulo-header {
  font-size: 50px;
  margin: 0 57px;
  max-width: 800px;
}

.img-blog-header {
  width: 100%;
  max-width: 500px;
  margin: 20px 0;
}

.subtitulo-header {
  margin: 0 40px;
}

/* media queries header */

@media (max-width: 1199px) {
  .logo-header {
    height: 280px;
  }
}

@media (max-width: 991px) {
  .titulo-header {
    font-size: 42px;
  }

  .subtitulo-header {
    font-size: 19px;
  }

  .logo-header {
    height: 165px;
    width: 165px;
  }
}
