/* formulario de cadastro */

.container {
  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container h1 {
  padding-bottom: 1.2rem;
}

.container section {
  padding-bottom: 1.8rem;
}

.container .btn-send {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  display: inline-block;
  padding-top: 14px;
}

.textarea {
  width: 100%;
}

.form-opt {
  padding-bottom: 1.2rem;
}

.campo-texto {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.38);
  border-width: 1px;
  padding: 15px;
  margin: 15px;
  color: rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}
