.inicio {
  width: 100%;
  min-height: 90vh;
  background-color: #f66464;
  display: flex;
  flex: 9;
  flex-direction: row;
  align-items: center;
  padding: 10%;
}

.texto_inicial {
  flex: 6;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  color: white;
}

.texto_inicial h2 {
  font-size: 55px !important;
}

.titles {
  font-size: 40px !important;
}

p {
  font-size: 20px;
}

.link_lattes {
  word-break: break-all;
}

.card_texto {
  text-align: justify;
}

.imagem_inicial {
  flex: 3;
  align-self: center;
}

.foto_inicial {
  width: 130%;
  height: 130%;
}

.foto_inicial img {
  width: 100%;
  height: auto;
}

.conjunto_botoes {
  display: flex;
  flex-direction: row;
  flex: 12;
  background-color: #f66464;
  width: 100%;
  height: 8vh;
}

.conjunto_botoes2 {
  display: flex;
  flex-direction: row;
  flex: 12;
  background-color: #4cf57c;
  width: 100%;
  height: 8vh;
}

.botao_tab1 {
  z-index: 10;
  flex: 4;
  display: flex;
  border: solid black;
  border-width: 0 1px 2px 1px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}

.botao_tab1:hover {
  cursor: pointer;
  filter: opacity(90%);
}

.botao_tab2 {
  z-index: 10;
  flex: 4;
  display: flex;
  border: solid black;
  border-width: 0 1px 2px 1px;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  font-weight: 400;
  text-align: center;
}

.botao_tab2:hover {
  cursor: pointer;
  filter: opacity(90%);
}

.tabs {
  width: 100%;
  height: 81vh;
  background-color: #4cf57c;
}

.tabs2 {
  width: 100%;
  height: 81vh;
  background-color: #5866f5;
}

.tab {
  width: 100%;
  height: 80vh;
  position: relative;
}

.tab_central {
  width: 100%;
  height: 80vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #4cf57c;
}

.tab_central2 {
  width: 100%;
  min-height: 80vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #5866f5;
}

.card_tab1 {
  width: 85%;
  height: 50vh;
  background-color: aliceblue;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  border-radius: 0 0 15px 15px;
}

.circulo_card_tab1 {
  width: 35vh;
  height: 35vh;
  background-color: aliceblue;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 15vh;
  margin-bottom: 15vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.circulo_card_tab1 img {
  height: 35vh;
  border-radius: 50%;
}

.circulo_card_tab12 {
  width: 35vh;
  height: 35vh;
  background-color: aliceblue;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 15vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.circulo_card_tab12 img {
  height: 35vh;
  border-radius: 50%;
}

.card_tab2 {
  width: 85%;
  height: 50vh;
  background-color: aliceblue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  border-radius: 0 0 15px 15px;
}

.circulo_card_tab2 {
  width: 35vh;
  height: 35vh;
  background-color: aliceblue;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  margin-bottom: 10vh;
  display: flex;
  justify-content: center;
}

.circulo_card_tab2direita {
  width: 31vh;
  height: 32.7vh;
  background-color: aliceblue;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  margin-bottom: 10vh;
  display: flex;
  justify-content: center;
}

.circulo_card_tab2 img {
  height: 35vh;
  border-radius: 50%;
}

.circulo_card_tab2direita img {
  height: 35vh;
  border-radius: 50%;
}

.card_tab3 {
  width: 85%;
  height: 50vh;
  background-color: aliceblue;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  border-radius: 0 0 15px 15px;
}

.circulo_card_tab3 {
  width: 35vh;
  height: 35vh;
  background-color: aliceblue;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 15vh;
  margin-bottom: 10vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.circulo_card_tab32 {
  width: 35vh;
  height: 35vh;
  background-color: aliceblue;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 15vh;
  margin-bottom: 10vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.circulo_card_tab3 img {
  height: 35vh;
}

.circulo_card_tab32 img {
  height: 35vh;
  border-radius: 50%;
}

.noticias_previa {
  width: 100%;
  height: 90vh;
  background-color: #f5db7d;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
}

.previa_da_noticia {
  height: 80%;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: initial;
  transition: filter 0.2s;
}

.previa_da_noticia img {
  display: block;
  width: 100%;
  max-height: 70%;
  border-radius: 15px 15px 0 0;
}

.previa_da_noticia:hover {
  filter: brightness(90%);
  cursor: pointer;
}

.div_titulo_noticia_previa {
  width: 100%;
  height: 100%;
  background-color: #f66464;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  border-radius: 0 0 15px 15px;
}

.historia {
  width: 100%;
  height: 150vh;
  background-color: #f66464;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}

.card_historia {
  width: 80%;
  height: 80vh;
  position: relative;
  display: flex;
  flex: 10;
  flex-direction: column;
  align-items: center;
}

.card_historia_titulo {
  flex: 3;
  width: 80%;
  background-color: #f5db7d;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card_historia_texto {
  flex: 7;
  width: 80%;
  background-color: aliceblue;
  margin-bottom: 15vh;
  padding: 10px 15vh 5vh 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 0 15px 15px;
}

.card_historia_circulo {
  width: 35vh;
  height: 35vh;
  background-color: aliceblue;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 10px 5px -1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.card_historia_circulo img {
  height: 35vh;
  border-radius: 50%;
}

.visao {
  width: 100%;
  height: 90vh;
  background-color: #4cf57c;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}

.card_visao {
  width: 80%;
  height: 80vh;
  position: relative;
  display: flex;
  flex: 10;
  flex-direction: column;
  align-items: center;
}

.card_visao_titulo {
  flex: 3;
  width: 80%;
  background-color: #f66464;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card_visao_texto {
  flex: 7;
  width: 80%;
  background-color: aliceblue;
  margin-bottom: 15vh;
  padding: 10px 15vh 5vh 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 0 15px 15px;
}

.card_visao_circulo {
  width: 35vh;
  height: 35vh;
  background-color: aliceblue;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 10px 5px 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.card_visao_circulo img {
  height: 35vh;

  border-radius: 50%;
}

.trabalho {
  width: 100%;
  height: 150vh;
  background-color: #4cf57c;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}

.card_trabalho {
  width: 80%;
  height: 80vh;
  position: relative;
  display: flex;
  flex: 10;
  flex-direction: column;
  align-items: center;
}

.card_trabalho_titulo {
  flex: 3;
  width: 80%;
  background-color: #f66464;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card_trabalho_texto {
  flex: 7;
  width: 80%;
  background-color: aliceblue;
  margin-bottom: 15vh;
  padding: 10px 15vh 5vh 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 0 15px 15px;
}

.card_trabalho_circulo {
  width: 35vh;
  height: 35vh;
  background-color: aliceblue;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 10px 5px 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.card_trabalho_circulo img {
  height: 35vh;

  border-radius: 50%;
}

.missao {
  width: 100%;
  height: 90vh;
  background-color: #f5db7d;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}

.beneficio {
  width: 100%;
  height: 150vh;
  background-color: #f5db7d;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}

.card_missao {
  width: 80%;
  height: 80vh;
  position: relative;
  display: flex;
  flex: 10;
  flex-direction: column;
  align-items: center;
}

.card_missao_titulo {
  flex: 3;
  width: 80%;
  background-color: #4cf57c;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card_missao_texto {
  flex: 7;
  width: 80%;
  background-color: aliceblue;
  margin-bottom: 15vh;
  padding: 10px 15vh 5vh 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 0 15px 15px;
}

.card_missao_circulo {
  width: 35vh;
  height: 35vh;
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 10px 5px 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_missao_circulo img {
  height: 20vh;
  border-radius: 10%;
}

.valores {
  width: 100%;
  height: 90vh;
  background-color: #5866f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}

.card_valores {
  width: 80%;
  height: 80vh;
  position: relative;
  display: flex;
  flex: 10;
  flex-direction: column;
  align-items: center;
}

.card_valores_titulo {
  flex: 3;
  width: 80%;
  background-color: #f5db7d;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card_valores_texto {
  flex: 7;
  width: 80%;
  background-color: aliceblue;
  margin-bottom: 15vh;
  padding: 10px 15vh 5vh 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 0 15px 15px;
}

.card_valores_circulo {
  width: 35vh;
  height: 35vh;
  background-color: aliceblue;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 10px 5px 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.card_valores_circulo img {
  height: 35vh;

  border-radius: 50%;
}

.equipe {
  width: 100%;
  height: 90vh;
  background-color: #f66464;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}

.card_equipe {
  width: 80%;
  height: 80vh;
  position: relative;
  display: flex;
  flex: 10;
  flex-direction: column;
  align-items: center;
}

.card_equipe_titulo {
  flex: 3;
  width: 80%;
  background-color: #5866f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card_equipe_texto {
  flex: 7;
  width: 80%;
  background-color: aliceblue;
  margin-bottom: 15vh;
  padding: 10px 15vh 5vh 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 0 15px 15px;
}

.card_equipe_circulo {
  width: 13.5vw;
  height: 13.5vw;
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 10px 5px 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_equipe_circulo img {
  max-height: 14vw;
  border-radius: 50%;
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .circulo_card_tab3,
  .card_visao_circulo,
  .card_missao_circulo,
  .card_valores_circulo {
    width: 13.5vw;
    height: 13.5vw;
  }
  .botao_tab2 {
    font-size: 20px;
  }
  .card_historia_circulo {
    width: 9vw;
    height: 9vw;
    margin-top: -10px;
  }
  .card_trabalho_circulo {
    width: 17vw;
    height: 17vw;
    margin-left: -10px;
  }
  .card_equipe_circulo {
    width: 15.5vw;
    height: 15.5vw;
    margin-right: -28px;
    margin-top: -5px;
  }
  .card_missao_circulo {
    width: 17vw;
    height: 17vw;
    margin-right: -20px;
  }
  .circulo_card_tab1 {
    width: 17vw;
    height: 17vw;
  }
  .circulo_card_tab32 {
    width: 17vw;
    height: 17vw;
  }
  .circulo_card_tab2direita {
    width: 17vw;
    height: 17vw;
  }
  .circulo_card_tab3 {
    width: 17vw;
    height: 17vw;
  }
  .card_valores_circulo {
    width: 16vw;
    height: 16vw;
  }
  .card_trabalho_circulo {
    width: 16vw;
    height: 16vw;
  }
}
@media (min-width: 2047px) and (max-width: 2048px) {
  .texto_inicial h2 {
    font-size: 110px !important;
  }

  .texto_inicial p {
    font-size: 40px !important;
  }

  .MuiButtonBase-root {
    min-height: 70px;
  }

  span.MuiButton-label {
    font-size: 28px;
  }

  .card_tab1 p {
    font-size: 40px !important;
  }

  .circulo_card_tab1 {
    width: 30vh !important;
    height: 30vh !important;
    margin-right: 8vh;
  }

  .circulo_card_tab1 img {
    width: 30vh !important;
    height: 30vh !important;
  }
}

@media (max-width: 280px) {
  .texto_inicial {
    margin-top: 43vw !important;
  }
}

@media (min-width: 319px) and (max-width: 321px) {
  .texto_inicial {
    margin-top: 36vw !important;
  }
}

@media (min-width: 359px) and (max-width: 376px) {
  .texto_inicial {
    margin-top: 32vw !important;
  }
}

@media (max-width: 700px) {
  .texto_inicial {
    min-width: 84.54vw !important;
    height: 45vh !important;
    margin-top: 29vw;
  }

  .texto_inicial h2 {
    font-size: 3.261vh !important;
  }

  .texto_inicial p {
    font-size: 2.582vh !important;
  }
  .card_equipe_circulo img {
    height: 15vw;
    width: 15vw;
    border-radius: 50%;
  }
  .inicio {
    padding: 5%;
    width: 100vw;
    flex-direction: column;
  }

  .tab {
    margin-top: 15vh;
  }

  .circulo_card_tab1 {
    width: 26.7vw;
    height: 26.7vw;
    margin-bottom: 76.15vh;
    margin-right: 61.18vw;
  }

  .circulo_card_tab1 img,
  .circulo_card_tab2direita img,
  .circulo_card_tab2 img,
  .circulo_card_tab3 img,
  .circulo_card_tab12 img,
  .circulo_card_tab32 img {
    width: 26.7vw;
    height: 26.7vw;
  }

  .card_tab1 {
    width: 96vw;
    height: 88.88vw;
    margin-left: 1.71vw;
    border-radius: 15px 15px 15px 15px;
    padding: 0 7.24vw !important;
  }

  .card_tab1 p {
    font-size: 5.79vw !important;
    margin-bottom: 0px;
  }

  .circulo_card_tab2direita {
    width: 26.7vw;
    height: 26.7vw;
    margin-bottom: 62.5vh;
    margin-right: 36.74vw;
  }

  .circulo_card_tab2 {
    width: 26.7vw;
    height: 26.7vw;
    margin-bottom: 62.5vh;
    margin-left: 12.5vw;
  }

  .circulo_card_tab12 {
    width: 26.7vw;
    height: 26.7vw;
    margin-bottom: 62.5vh;
    margin-right: 12.5vw;
  }

  .card_tab2 {
    width: 96vw;
    height: 88.88vw;
    border-radius: 15px 15px 15px 15px;
    padding: 0 7.24vw !important;
    margin-top: 12.79vh;
    margin-bottom: 15vh;
  }

  .card_tab2 p {
    font-size: 5.79vw !important;
    margin-bottom: 0px;
    margin-top: 5vh !important;
    overflow: scroll;
  }

  .card_tab3 {
    width: 96vw;
    height: 88.88vw;
    margin-right: 1.71vw;
    border-radius: 15px 15px 15px 15px;
    padding: 0 7.24vw !important;
  }

  .card_tab3 p {
    font-size: 5.79vw !important;
    margin-bottom: 0px;
  }

  .circulo_card_tab3 {
    width: 26.7vw;
    height: 26.7vw;
    margin-bottom: 76.15vh;
    margin-left: 61.18vw;
  }

  .circulo_card_tab32 {
    width: 26.7vw;
    height: 26.7vw;
    margin-bottom: 76.15vh;
    margin-left: 61.18vw;
  }

  .card_historia_titulo,
  .card_visao_titulo,
  .card_missao_titulo,
  .card_valores_titulo,
  .card_equipe_titulo,
  .card_trabalho_titulo {
    width: 96vw !important;
    max-height: 12.77vh !important;
    padding-left: 7.24vw;
    padding-right: 7.24vw;
  }

  .card_historia_titulo h3,
  .card_visao_titulo h3,
  .card_missao_titulo h3,
  .card_valores_titulo h3,
  .card_equipe_titulo h3,
  .card_trabalho_titulo h3 {
    font-size: 6.28vw !important;
  }

  h3#titulo_missao2 {
    font-size: 5.28vw !important;
  }

  .card_historia_texto p,
  .card_visao_texto p,
  .card_missao_texto p,
  .card_valores_texto p,
  .card_equipe_texto p,
  .card_trabalho_texto p {
    font-size: 5.19vw !important;
    overflow: scroll;
    margin-top: 5vh;
  }

  .card_historia_texto,
  .card_visao_texto,
  .card_missao_texto,
  .card_valores_texto,
  .card_equipe_texto,
  .card_trabalho_texto {
    width: 96vw !important;
    height: 88.88vw !important;
    padding: 0px 7.24vw 0px 7.24vw;
  }

  .card_historia_circulo,
  .card_missao_circulo,
  .card_equipe_circulo {
    margin-top: 6.52vh;
    margin-right: -3.62vw;
    width: 16.9vw;
    height: 16.9vw;
  }

  .card_valores_circulo,
  .card_visao_circulo,
  .card_trabalho_circulo {
    margin-top: 6.52vh;
    margin-left: -3.62vw;
    width: 16.9vw;
    height: 16.9vw;
  }

  .card_historia_circulo img,
  .card_visao_circulo img,
  .card_valores_circulo img,
  .card_trabalho_circulo img {
    width: 16.9vw;
    height: 16.9vw;
  }

  .card_missao_circulo img {
    width: 16.9vw;
    height: 16.9vw;
    border-radius: 50%;
  }

  .card_equipe_titulo {
    min-height: 10vh;
  }

  footer.page-footer {
    margin: 0px !important;
  }

  .previa_da_noticia {
    width: 70vw;
    height: 36vh;
  }

  .div_titulo_noticia_previa h5 {
    font-size: 3.5vw !important;
  }

  .imagem_inicial,
  .foto_inicial,
  .foto_inicial img {
    width: 33.57vw !important;
  }
  .botao_tab2 {
    font-size: 4vw;
    text-align: center;
  }
  .botao_tab1 {
    font-size: 4.83vw;
  }
}

@media (min-width: 701px) and (max-width: 1024px) {
  .inicio {
    flex-direction: column;
  }

  .imagem_inicial,
  .foto_inicial,
  .foto_inicial img {
    width: 50vw !important;
  }

  .texto_inicial h2 {
    font-size: 6vw !important;
  }

  .texto_inicial p,
  .card_tab1 p,
  .card_tab2 p,
  .card_tab3 p {
    font-size: 2.7vw !important;
  }

  .circulo_card_tab1 {
    width: 35vw;
    height: 35vw;
    margin-right: 8vw;
  }

  .circulo_card_tab2,
  .circulo_card_tab12 {
    width: 35vw;
    height: 35vw;
  }

  .circulo_card_tab1 img,
  .circulo_card_tab12 img,
  .circulo_card_tab2 img {
    width: 35vw;
    height: 35vw;
  }

  .previa_da_noticia {
    width: 70vw;
    height: 36vh;
  }

  .div_titulo_noticia_previa h5 {
    font-size: 3.5vw !important;
  }

  .card_equipe_titulo {
    min-height: 17vh;
  }

  .card_historia_titulo,
  .card_visao_titulo,
  .card_missao_titulo,
  .card_valores_titulo,
  .card_equipe_titulo,
  .card_trabalho_titulo {
    width: 96vw !important;
    max-height: 12.77vh !important;
    padding-left: 7.24vw;
    padding-right: 7.24vw;
  }

  .card_historia_titulo h3,
  .card_visao_titulo h3,
  .card_missao_titulo h3,
  .card_valores_titulo h3,
  .card_equipe_titulo h3,
  .card_trabalho_titulo h3 {
    font-size: 5.28vw !important;
  }

  h3#titulo_missao2 {
    font-size: 4.28vw !important;
  }

  .card_historia_texto p,
  .card_visao_texto p,
  .card_missao_texto p,
  .card_valores_texto p,
  .card_equipe_texto p,
  .card_trabalho_texto p {
    font-size: 4.5vw !important;
    overflow: scroll;
    margin-top: 5vh;
  }

  .card_historia_texto,
  .card_visao_texto,
  .card_missao_texto,
  .card_valores_texto,
  .card_equipe_texto,
  .card_trabalho_texto {
    width: 96vw !important;
    height: 88.88vw !important;
    padding: 0px 7.24vw 0px 7.24vw;
  }

  .card_historia_circulo,
  .card_missao_circulo,
  .card_equipe_circulo {
    margin-top: 6.52vh;
    margin-right: -3.62vw;
    width: 16.9vw;
    height: 16.9vw;
  }

  .card_valores_circulo,
  .card_visao_circulo,
  .card_trabalho_circulo {
    margin-top: 6.52vh;
    margin-left: -3.62vw;
    width: 16.9vw;
    height: 16.9vw;
  }

  .card_historia_circulo img,
  .card_visao_circulo img,
  .card_valores_circulo img,
  .card_trabalho_circulo img {
    width: 16.9vw;
    height: 16.9vw;
  }

  .card_missao_circulo img,
  .card_equipe_circulo img {
    width: 16.9vw;
    height: 16.9vw;
    border-radius: 50%;
  }

  footer.page-footer {
    margin: 0px !important;
  }

  .botao_tab1,
  .botao_tab2 {
    font-size: 4vw;
  }
}

@media (min-width: 1921px) {
  .imagem_inicial,
  .foto_inicial,
  .foto_inicial img {
    width: 30vw !important;
  }

  .texto_inicial h2 {
    font-size: 4vw !important;
  }

  .texto_inicial p,
  .card_tab1 p,
  .card_tab2 p,
  .card_tab3 p {
    font-size: 1.75vw !important;
  }

  .card_tab1,
  .card_tab2,
  .card_tab3 {
    height: 35vh !important;
  }

  .circulo_card_tab1 {
    width: 23vw;
    height: 23vw;
    margin-right: 8vw;
    margin-bottom: 26vh;
  }

  .circulo_card_tab2,
  .circulo_card_tab2direita,
  .circulo_card_tab12,
  .circulo_card_tab3,
  .circulo_card_tab32 {
    width: 23vw;
    height: 23vw;
    margin-bottom: 20vh !important;
  }

  .circulo_card_tab1 img,
  .circulo_card_tab2direita img,
  .circulo_card_tab12 img,
  .circulo_card_tab2 img,
  .circulo_card_tab3 img,
  .circulo_card_tab32 img {
    width: 23vw;
    height: 23vw;
  }

  .previa_da_noticia {
    width: 45vw;
    height: 50vh;
  }

  .div_titulo_noticia_previa h5 {
    font-size: 1.75vw !important;
  }

  .card_historia_titulo h3,
  .card_visao_titulo h3,
  .card_missao_titulo h3,
  .card_valores_titulo h3,
  .card_equipe_titulo h3,
  .card_trabalho_titulo h3 {
    font-size: 3vw !important;
  }

  h3#titulo_missao2 {
    font-size: 2.3vw !important;
  }

  .card_historia_texto p,
  .card_visao_texto p,
  .card_missao_texto p,
  .card_valores_texto p,
  .card_equipe_texto p,
  .card_trabalho_texto p {
    font-size: 1.73vw !important;
    overflow: scroll;
    margin-top: 5vh;
  }

  .card_historia_circulo,
  .card_missao_circulo,
  .card_equipe_circulo {
    width: 20vw !important;
    height: 20vw !important;
  }

  .card_valores_circulo,
  .card_visao_circulo,
  .card_trabalho_circulo {
    width: 20vw !important;
    height: 20vw !important;
  }

  .card_historia_circulo img,
  .card_visao_circulo img,
  .card_valores_circulo img,
  .card_trabalho_circulo img {
    width: 20vw !important;
    height: 20vw !important;
  }

  .card_missao_circulo img,
  .card_equipe_circulo img {
    width: 20vw !important;
    height: 20vw !important;
    border-radius: 50%;
  }

  footer.page-footer {
    margin: 0px !important;
  }
}
