/* formulario de cadastro */

.container {
  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#datainicial{
  font-size: 15px;
  color: red;
  float: center;
  text-align: center;
  align-items: center;
}
.container h1 {
  padding-bottom: 1.2rem;
}

.container section {
  padding-bottom: 1.8rem;
}
#concluirsv{
  align-items: center;
  text-align: center;
  float: center;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-send2{
  display: flex;
  align-items: center;
  justify-content: center;
}
.input {
  display: inline-block;
  padding-top: 14px;
}

.textarea {
  width: 100%;
}

.form-opt {
  padding-bottom: 1.2rem;
}
.imagem_copo {
  max-width: 32%;
  margin-bottom: 16px;
}

.imagens-legenda {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imagens-legenda-sol {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
