.cadastro-padrao-container {
  color: aliceblue;
  width: 100%;
  height: 50%;
  min-height: 100vh;
  display: flex;
  flex: 12;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  background-color: #f5db7d;
}

.lateral-esquerda-login {
  background-color: #f66464;
  flex: 6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 50% 50% 0;
}

.lateral-esquerda-login-voltar {
  position: absolute;
  top: 10px;
  left: 2%;
  padding: 10px 20px 5px 15px;
  border-radius: 50px;
  background-color: #f54c4c;
  transition: filter 0.2s;
}

.lateral-esquerda-login-voltar:hover {
  filter: saturate(90%);
}

.titulo-lateral {
  margin-bottom: 30px;
  padding: 0 5px;
}

.descricao-opcional-lateral {
  margin-top: 30px;
}

.lateral-direita-login {
  color: #f54c4c;
  flex: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.titulo-login {
  font-size: 60px !important;
}

form {
  width: 70%;
}

.entrada {
  width: 100%;
  margin: 5px 0;
}

.botoes-entrada {
  margin-top: 10px;
}

.cadastro-container {
  background-color: #f66464;
  width: 100%;
  height: 90vh;
  display: flex;
  flex: 8;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.texto-cadastro {
  background-color: #f5db7d;
  color: #f54c4c;
  width: 100%;
  border-radius: 25% 0 0 0 / 50% 0;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.opcoes-cadastro {
  background-color: #f5db7d;
  width: 100%;
  flex: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 25% 0 / 12.5%;
}

.botoes-cadastro {
  color: aliceblue;
  margin: 15px;
  background-color: #f66464;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30%;
  max-height: 100px;
  width: 90%;
  max-width: 800px;
  border-radius: 16px;
  transition: filter 0.2s;
}

.botoes-cadastro:hover {
  filter: opacity(90%);
}

h1 {
  font-size: 32px !important;
  text-align: center;
}

h4 {
  font-size: 20px !important;
  text-align: center;
}

.voltar-site {
  color: white;
}

.voltar-site-link:hover {
  text-decoration: none;
}

.botoes-entrada {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 1025px) {
  .lateral-esquerda-login {
    padding: 0 3vw 0 10px !important;
  }
}

@media (max-width: 1024px) {
  h1 {
    font-size: 28px !important;
  }

  h4 {
    font-size: 18px !important;
  }

  .login-container {
    color: aliceblue;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 12;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    background-color: #f5db7d;
  }
  .lateral-esquerda-login {
    background-color: #f66464;
    flex: 3 1;
    min-width: 100%;
    min-height: 50vh;
    border-radius: 0 0 15% 15%;
    padding-top: 80px;
  }
  .descricao-opcional-lateral {
    display: none;
  }
  .lateral-direita-login {
    color: #f54c4c;
    flex: 9;
    width: 100%;
  }
}

@media (max-width: 450px) {
  h1 {
    font-size: 18px !important;
  }
  h4 {
    font-size: 12px !important;
  }
  .lateral-esquerda-login {
    height: 100%;
    padding-top: 80px;
    flex: 3;
  }
  .descricao-lateral {
    font-size: 18px !important;
  }
  .lateral-direita-login {
    flex: 9;
  }
  .titulo-login {
    font-size: 40px !important;
  }
}
