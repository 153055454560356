.linha-da-lista {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.itens-da-linha {
  width: 100%;
  border-style: solid;
  border-color: #f5db7d;
  background-color: #f5db7d;
  border-width: 0 1px 1px 1px;
}

.itens1ESP {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 13ch;
  justify-content: center;
  position: absolute;
  margin-left: -620px;
}
.itens2ESP {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: hidden;
  max-width: 115px;
  margin-left: -96px;
  justify-content: center;
  display: block;
  float: none;
  position: absolute;
}
.itens3ESP {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 14ch;
  justify-content: center;
  display: block;
  float: none;
  position: absolute;
  margin-left: 510px;
}
@media (max-width: 492px) {
  #modaldados{
    width: fit-content;
    height: fit-content;
    margin-left: -10px;
  }
  .lista-geral-table-wrapper{
    margin-left: -37px;
    width: 100%;
    margin-right: -37px;

  }
}