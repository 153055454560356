.sidebar {
  color: white;
  font-size: 16px;
  text-decoration: none;
}

.sidebar-content {
  height: 100%;
  flex: 3;
  min-width: 200px;
  max-width: 250px;
  background-color: #f67474;
}

.sidebar-collapse-button {
  height: 86px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f54c4c;
  margin: auto;
}

.sidebar-options {
  display: flex;
  flex-direction: column;
}

.wraptext {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f67474;
  border-radius: 8px;
  margin-top: 2px;
  color: #ffffff;
}

.wraptext:hover {
  filter: brightness(96%);
}

.sidebar-content-collapsed {
  flex: 1;
  max-width: 50px;
  background-color: #f67474;
  justify-content: center;
  height: 100%;
}

.item-menu {
  margin-left: 10px;
}

@media (max-width: 751px) {
}
