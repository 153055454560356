.dia-disponibilidade {
  margin: 10px auto;
  display: flex;
  flex: 12;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  min-width: 600px;
}

.texto-picker {
  margin: 0 10px;
  flex: 10;
  width: 300px;
}

.picker {
  padding-left: 20px;
  flex: 2;
}

.grade-horaria {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.linha-grade {
  min-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.item-grade-titulo {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3644df;
  border: beige 0.5px solid;
  border-color: beige;
  color: azure;
}

.item-grade {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5db7d;
  border: beige 0.5px solid;
  border-color: beige;
  transition: filter 0.2s;
}

.item-grade:hover {
  filter: saturate(70%);
  cursor: pointer;
}

.marcado {
  background-color: #5866f5;
  color: azure;
}

.dias-da-semana-seg {
  border-radius: 5px 0 0 0;
}

.dias-da-semana-sab {
  border-radius: 0 5px 0 0;
}

.vinteduas-horas-seg {
  border-radius: 0 0 0 5px;
}

.vinteduas-horas-sab {
  border-radius: 0 0 5px 0;
}
