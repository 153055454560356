.titulo-da-lista-pesquisadores {
  font-size: medium;
  margin-top: 10px;
}

.titulo-da-lista-estudantes {
  font-size: medium;
  margin-top: 30px;
}

.titulo-linha-da-lista {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0 0 0;
}

.titulo-itens-da-linha {
  width: 100%;
  border-style: solid;
  border-color: #f5db7d;
  background-color: #f5db7d;
  border-width: 1px;
  display: flex;
  flex: 12;
  flex-direction: row;
  justify-content: space-around;
}

.titulo-itens1{
  justify-content: center;
  position: absolute;
  margin-left: -620px;
}
.titulo-itens3{
  justify-content: center;
  position: absolute;
  margin-left: -90px;
}

@media (max-width: 492px){
  .titulo-itens1{
    margin-left: -140px;
    
  }
  .titulo-itens3{
   margin-left: 120px;
    
    
  }
  
}
@media only screen and (min-width: 834px) and (max-width: 1024px){
  .titulo-itens1{
    margin-left: -280px;
    
  }
  .titulo-itens3{
   margin-left: 260px;
    
    
  }
  
}
@media only screen and (min-width: 1024px) and (max-width: 1366px){
  .titulo-itens1{
    margin-left: -400px;
    
  }
  .titulo-itens3{
   margin-left: 370px;
    
    
  }
 
}
@media only screen and (width: 768px) and (height: 1024px){
  .titulo-itens1{
    margin-left: -270px;
    
    
  }
  .titulo-itens3{
   margin-left: 235px;
    
    
  }
 
}
.titulo-botoes-lista {
  background-color: #5866f5;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
