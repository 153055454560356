.mensagem-linha-da-lista {
  width: 100%;
  background-color: #f5db7d;
  display: flex;
  flex: 12;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5px 0;
  height: min-content !important;
}

.mensagem-linha1-lista {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mensagem-linha1-linha1-lista {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 12;
  padding: 0 10px;
  background-color: #ffd950;
}

.mensagem-forum-autor {
  flex: 4;
  width: 100%;
  align-items: flex-start;
  font-weight: 400;
}

.mensagem-forum-titulo {
  flex: 4;
  width: 100%;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  margin-top: 15px;
  white-space: normal;
}

.mensagem-forum-hora_data {
  flex: 4;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: 200;
}

.mensagem-linha2-linha1-lista {
  display: flex;
  justify-content: center;
  width: 80%;
  font-size: 12px;
  font-weight: 100;
  background-color: #e2dbc2;
  margin: 5px 0;
}

.mensagem-linha2-lista {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mensagem-forum-conteudo {
  margin: 5px 0;
  justify-content: flex-start;
  width: 90%;
  font-size: 18px;
}

.mensagem-forum-anexo {
  width: 80%;
  object-fit: cover;
}

.mensagem-linha3-lista {
  display: flex;
  justify-content: flex-end;
}

/* formulario de cadastro */

.container {
  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container h1 {
  padding-bottom: 1.2rem;
}

.container section {
  padding-bottom: 1.8rem;
}

.container .btn-send {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  display: inline-block;
  padding-top: 14px;
}

.textarea {
  width: 100%;
}

.sinal-resposta {
  margin-bottom: 15px;
}
@media (max-width: 751px) {
  .mensagem-forum-titulo {
    flex: 7;
    min-width: 100%;
  }
}
