.item-rede-social {
  margin: 0 10px;
  list-style-type: none;
  float: right;
  font-size: 40px;
}

.redes-sociais {
  display: flex;
  justify-content: center;
}

.coluna_footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.footer-slogan-reserva:hover,
.footer-slogan:hover,
.footer-logo:hover,
.footer-links:hover {
  text-decoration: none;
  color: #ffffffd0;
}

@media (min-width: 768px) and (max-width: 978px) {
  .item-rede-social {
    float: none;
  }
}

@media (max-width: 337px) {
  .item-rede-social {
    float: none;
  }
}

@media (min-width: 846px) {
  .footer-slogan-reserva {
    display: none;
  }
}

@media (max-width: 845px) {
  .footer-slogan {
    display: none;
  }
}
