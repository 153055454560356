/* .instrumento-preenchido {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.2rem auto;
} */

.lista-instrumentos-preenchidos {
  margin: 1.6rem 0;
}

.icon-right {
  margin-right: 1.2rem;
}
span{
  align-items: center;
  text-align: center;
}
.pasta-consulta {
  
  align-items: center;
  
}

#instmodal{
  margin: 40px;
  text-align:justify;
  font-size: 20px;
  
  
}
#mdaltapaciente{
  
}
.top{
  align-items: center;
  text-align: center;
  
}

.MuiButtonBase-root{
  margin-right: 10px;
  margin-bottom: 10px;
            }
@media (max-width: 492px){
  .modal {
   max-width: 85%;
   max-height: fit-content;
   margin-left: 55px;
}
}