.card-lembrete {
  width: 100%;
  min-height: 100px;
  border-style: solid;
  border-color: #f5db7d;
  border-radius: 5px;
  background-color: #f5db7d;
  border-width: 1px;
  margin-bottom: 10px;
  display: flex;
  flex: 12;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

/* .data-proxima-consulta {

}

.pesquisador-responsavel-consulta {

} */

.link-instrumentos-preencher {
  color: #111111;
  margin: 10px;
}

.link-instrumentos-preencher:hover {
  text-decoration: none;
  color: #111111;
  opacity: 0.8;
}

.card-link-instrumentos {
  width: 100%;
  min-height: 100px;
  border-style: solid;
  border-color: #f5db7d;
  border-radius: 5px;
  background-color: #f5db7d;
  border-width: 1px;
  margin-bottom: 10px;
  display: flex;
  flex: 12;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  
  
}

.card-link-para-forum {
  width: 100%;
  min-height: 100px;
  border-style: solid;
  border-color: #f5db7d;
  border-radius: 5px;
  background-color: #f5db7d;
  border-width: 1px;
  margin-bottom: 10px;
  display: flex;
  flex: 12;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.card-link-para-forum:hover {
  text-decoration: none;
  opacity: 0.8;
}