.linha-da-lista {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.itens-da-linha {
  width: 100%;
  border-style: solid;
  border-color: #f5db7d;
  background-color: #f5db7d;
  border-width: 0 1px 1px 1px;
}
.itens1B{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 13ch;
  justify-content: center;
  position: absolute;
  margin-left: -610px;
}
.itens2B{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: hidden;
  max-width: 117px;
  margin-left: -90px;
  justify-content: center;
  display :block;
  float :none;
  position: absolute;
}
.itens3B {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 14ch;
  justify-content: center;
  display :block;
  float :none;
  position: absolute;
  margin-left: 520px;
}
@media (max-width: 492px){
  .itens1B{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 10ch;
    justify-content: center;
    margin: 15px;
    position: relative;
  }
  .itens2B{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: hidden;
    max-width: 117px;
    justify-content: center;
    margin: 15px;
    position: relative;
  }
  .itens3B {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 14ch;
    justify-content: center;
    margin: 15px;
    position: relative;
  }
}

.botoes-lista {
  background-color: #5866f5;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
