.titulo-linha-da-listaF {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.titulo-itens-da-linhaF {
  width: 100%;
  border-style: solid;
  border-color: #f5db7d;
  background-color: #f5db7d;
  border-width: 1px;
  display: flex;
  flex: 12;
  flex-direction: row;
  justify-content: space-around;
}

.titulo-itens1F {
  
  justify-content: center;
  position: absolute;
  margin-left: -620px;
}
.titulo-itens2F{
  
  justify-content: center;
  position: absolute;
  margin-left: 25px;
}
@media (max-width: 492px){
  .titulo-itens1F{
    margin-left: -20px;
    position: relative;
  }
  .titulo-itens2F{
   margin-left: -50px;
    position: relative;
    
  }
}
.titulo-botoes-listaF {
  background-color: #5866f5;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}
#aceitarcn{
  text-align: center;
  align-items: center;
}