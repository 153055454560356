.card-principal-adm {
  padding: 15px;
  border-radius: 32px;
  border-color: #5866f5;
  border-width: 8px;
  border-style: solid;
  height: 100%;
}

.card-principal-adm h1 {
  color: #4cf57c;
}

.card-principal-adm h1:hover {
  text-decoration: none !important;
}

.img,
.titulo,
.linha-img-titulo {
  margin: auto;
}

.img {
  color: #5866f5;
  font-size: 128px;
}

/* media queries */

@media (max-width: 991px) {
  .img {
    text-align: center;
  }
}

@media (max-width: 450px) {
  .img {
    font-size: 82.29px;
  }
}