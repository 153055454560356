.linha-da-lista {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.itens-da-linha {
  width: 100%;
  border-style: solid;
  border-color: #f5db7d;
  background-color: #f5db7d;
  border-width: 0 1px 1px 1px;
}
.itens1BP3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 13ch;
  justify-content: center;
  position: absolute;
  margin-left: -530px;
}
.itens2BP3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: hidden;
  max-width: 117px;
  margin-left: -35px;
  justify-content: center;
  display: block;
  float: none;
  position: absolute;
}
.itens3BP3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 14ch;
  justify-content: center;
  display: block;
  float: none;
  position: absolute;
  margin-left: 480px;
}
@media (max-width: 492px) {
  .itens1BP3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 10ch;
    justify-content: center;
    margin: 15px;
    position: inherit;
  }
  .itens2BP3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: hidden;
    max-width: 117px;
    justify-content: center;
    margin: 15px;
    position: inherit;
  }
  .itens3BP3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 14ch;
    justify-content: center;
    margin: 15px;
    position: inherit;
  }
}
@media only screen and (min-width: 834px) and (max-width: 1024px) {
  .itens1BP3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 10ch;
    justify-content: center;
    margin: 15px;
    position: relative;
  }
  .itens2BP3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: hidden;
    max-width: 117px;
    justify-content: center;
    margin: 15px;
    position: relative;
  }
  .itens3BP3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 14ch;
    justify-content: center;
    margin: 15px;
    position: relative;
  }
}
@media only screen and (width: 768px) and (height: 1024px) {
  .itens1BP3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 10ch;
    justify-content: center;
    margin: 15px;
    position: relative;
  }
  .itens2BP3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: hidden;
    max-width: 117px;
    justify-content: center;
    margin: 15px;
    position: relative;
  }
  .itens3BP3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 14ch;
    justify-content: center;
    margin: 15px;
    position: relative;
  }
}

.botoes-lista3BP3 {
  background-color: #5866f5;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-disp {
  display: flex;
  justify-content: center;
  text-align: center;
}

.lista-disponibilidades {
  height: 400px;
  overflow: auto;
}
