.picker {
  width: 75%;
  margin-top: 1.2rem;
}
#tituloprox{
  font-size: 30px;
}
.container {
  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}
#textoprox{
  font-size: 27px;
  color: #f66464;
  text-align: center;
}
.container h2 {
  color: #f66464;
}

.btn-send {
  margin-bottom: 1.2rem;
}
@media (max-width: 450px){
  #textoprox{
    align-items: left;
    text-align: left;
    font-size: 25px ; 
  }
  #tituloprox{
    text-align: left;
    align-items: left;
    
  }
  .btn-send {
    align-items: center;
    
  }
  #textinst{
    font-size: 20px;
    float: left;
    text-align: left;
    align-items: left;
  }
 
}