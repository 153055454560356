.MuiPickersCalendar-week > * {
  width: 40px;
  height: 40px;
}

.MuiButtonBase-root {
  margin:0;
}

.lista-disponibilidades {
  height: 400px;
  width: 50%;
  overflow: auto;
}

.calendariodisp{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}

@media (max-width: 768px) {
  .lista-disponibilidades {
    width: 100%;
  }
  
}
