.card-pag-prin-adm {
  width: 100%;
  min-height: 100px;
  border-style: solid;
  border-color: #f5db7d;
  border-radius: 5px;
  background-color: #f5db7d;
  border-width: 1px;
  margin-bottom: 10px;
  display: flex;
  flex: 12;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #111111;
  text-align: center;
}

.card-pag-prin-adm:hover {
  text-decoration: none;
  color: #111111;
  opacity: 0.8;
}
