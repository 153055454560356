.lista-geral-table-wrapper{
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  white-space: nowrap;
}

@media (max-width: 751px) {
  .lista-geral-table-wrapper{
    max-width: 80vw;
    overflow: scroll;
  }
}
