.subtitulo_instrucoes {
  width: 100%;
  font-size: 5px !important;
  color: red;
  float: center;
  text-align: center;
  align-items: center;
  text-align: justify;
}

.linha {
  width: 100%;
  height: 150px;
  display: flex;
  flex: 12;
  flex-direction: row;
  align-items: flex-start;
  background-color: #f5db7d !important;
  color: black !important;
  margin: 0 !important;
  box-shadow: inset -50px 0px 30px -30px #baa65e;
}

.campo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f5db7d !important;
  margin-top: 20px;
}

.titulo_campo {
  padding: 5px 0 0 15px;
}

.abrir_modal_evacuacao {
  width: 100px;
  height: 100px;
  background-color: #f66464;
  cursor: pointer;
  margin: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
}

.ocorrencias_evacuacao {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
}

.ocorrencias_miccao {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
}

.ocorrencias_liquido {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
}

.abrir_modal_miccao {
  width: 100px;
  height: 100px;
  background-color: #f66464;
  cursor: pointer;
  margin: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
}

.abrir_modal_liquido {
  width: 100px;
  height: 100px;
  background-color: #f66464;
  cursor: pointer;
  margin: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
}

.ocorrencia_evacuacao {
  width: 100px;
  height: 100px;
  background-color: aliceblue;
  margin: 10px 15px 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  display: inline-block;
}

.ocorrencia_miccao {
  width: 100px;
  height: 100px;
  background-color: aliceblue;
  margin: 10px 15px 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  display: inline-block;
}

.inner {
  height: 100%;
  white-space:nowrap;
}

.ocorrencia_liquido {
  width: 100px;
  height: 100px;
  background-color: aliceblue;
  margin: 10px 15px 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  display: inline-block;
}

.hora_card {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.texto_card {
  margin-top: 5px;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  justify-content: center;
}

.campos-formulario {
  display: flex;
  flex-direction: column;
}

section {
  margin: 10px 0;
}

.tempo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}