/* formulario de cadastro */

.container {
  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#p1{
  margin-left: 60px;
  display: inline-block;
}
#p2{ 
  margin-left: 140px;
  display: inline-block;
}
#p3{
  margin-left: 110px;
  display: inline-block;
}
#p4{
  margin-left: 87px;
  display: inline-block;
}
.container h1 {
  padding-bottom: 1.2rem;
}

.container section {
  padding-bottom: 1.8rem;
}
.container .btn-send {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  display: inline-block;
  padding-top: 14px;
}

.textarea {
  width: 100%;
}

/* template da plataforma*/

.body-wrapper {
  display: flex;
  flex-direction: row;
  flex: 12;
  background-color: #fffbee;
}

.subtitulo_instrucoes {
  width: 100%;
  font-size: 5px !important;
  color: red;
  float: center;
  text-align: center;
  align-items: center;
  text-align: justify;
}

.content-wrapper {
  flex: 9;
  min-height: 100vh;
}

.imagem_sol {
  max-width: 25%;
  margin-left: 20;
  margin-bottom: 16px;
}
.pagina {
  color: #111111;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.estilo-titulo {
  height: 10px;
  background-color: #f5db7d2f;
  width: 50%;
  margin-top: -20px;
}

.titulo-pagina {
  font-size: 28px;
  margin: 5px 0;
}

.conteudo-pagina {
  width: 100%;
  max-width: 1120px;
  height: 100%;
  margin: 5px auto;
}

.quadrados {
  max-width: 100px;
  height: 100px;
  background-color: brown;
  margin: 5px;
}

@media (max-width: 450px){
  .menosq{
    font-size: 10px;
  }
}
 
 @media only screen and (min-width: 834px) and (max-width: 1024px){
   #p1{
    overflow: hidden;
    white-space: nowrap;
    max-width: 17px;
    margin-left: 72px;
   }
   #p2{
    overflow: hidden;
    white-space: nowrap;
    max-width: 17px;
    margin-left: 157px;
   }
   #p3{
    overflow: hidden;
    white-space: nowrap;
    max-width: 17px;
    margin-left: 157px;
   }
   #p4{
    overflow: hidden;
    white-space: nowrap;
    max-width: 17px;
    margin-left: 155px;
   }
  
 }